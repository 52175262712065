import 'modernizr';
import 'main/webpack-runtime-settings.js';
import 'util/fixes';
import 'tocca';
import initSentry from './sentry';
import { installPolyfills, handleErrors } from 'util/polyfills';
//

window.googleRecaptchaCallback = function() {
    globals.state.googleRecaptchaLoaded = true;
};

/**
 * Main entry point to the js app. Javascript execution starts here
 * 1. Webpack config and general fixes are initialized via `import` statement
 * 2. Polyfills are imported and asynchronously processed via `Promise` API
 * 3. In case of success, the vue app is initialized
 */
installPolyfills
    .then(() => globals.config.debug ? null : initSentry())
    .then(() => require.ensure([], () => require('framework/base'), null, 'app-boot')) // eslint-disable-line global-require
    .then(app => app.init())
    .catch(handleErrors);
